import { CheckOutFlows, PaymentType } from './enum';

export class PickUpDTO {
  destinationPoint: string;

  address?: string;

  date: Date;

  note?: string;
}

export class DeliveryDTO {
  destinationPoint?: string;

  address: string;

  date?: Date;

  note?: string;
}

export class ShippingAddress {
  deliveryType: string;

  details: DeliveryDTO | PickUpDTO;
}

export class SubscriptionItem {
  basePlanId: string;

  itemPriceId: string;

  quantity: number;

  type: string;

  firebaseOnly?: boolean;

  price: number;

  discountAmount: number;

  period: number;

  zohoOnly?: boolean;

  zohoCode?: string;

  originalQuantity?: number;

  itemLevelDiscount?: number;
}

export class PaymentDTO {
  id?: string;

  paymentMethod?: PaymentType;

  bankName?: string;

  paidAmount: number;

  paymentDate: Date;

  invoiceUrls: string[];
}

export class BillingAddressInfo {
  vatNumber: string;
  city: string;
  countryCode?: string;
  country?: string;
  streetName: string;
  postalCode: string;
  districtName: string;
  companyName?: string;
  additionalIdentifier?: string;
  businessCategory?: string;
  additionalIdentifierNumber?: string;
  previousUsedSystem?: string;
  employeeCount?: string;
  isEmployee?: boolean;
  buildingNumber?: string;
  additionalNumber?: string;
  employeeName?: string;
  employeePhone?: string;
  bestCommunicationTime?: string;
  traineeContactNumber?: string;
  bestTimeToCommunicate?: string;
  integrateWithRewaaPay?: string;
  clientsIBAN?: string;
  needTrainingForAccounting?: boolean;
  expectedOnboardingDate?: string;
  daysToUploadProduct?: string;
  reasonForUploadingProducts?: string;
  hasWebStore?: boolean;
  webStoreType?: string;
  isRewaaPayIncluded?: boolean;
  isStockCountRequired?: boolean;
  isInPersonTrainingRequired?: boolean;
  isAdditionalTrainingRequired?: boolean;
  isOutboundTrainingRequired?: boolean;
}

export class TrainingInfo {
  integrateWithRewaaPay?: string;
  clientsIBAN?: string;
  needTrainingForAccounting?: boolean;
  expectedOnboardingDate?: string;
  daysToUploadProduct?: string;
  reasonForUploadingProducts?: string;
  hasWebStore?: boolean;
  webStoreType?: string;
  isRewaaPayIncluded?: boolean;
  isStockCountRequired?: boolean;
  isInPersonTrainingRequired?: boolean;
  isAdditionalTrainingRequired?: boolean;
  isOutboundTrainingRequired?: boolean;
  employeeName?: string;
  employeePhone?: string;
  bestCommunicationTime?: string;
  needRewaaPayIntegration?: boolean;
}

export class AdminOnlinePaymentDTO {
  id: string;

  userEmail: string;

  invoiceURL?: string;

  subscriptionItems: SubscriptionItem[];

  amount: number;

  checkOutFlow: CheckOutFlows;

  nowInTrial: boolean;

  isAccountActive: boolean;

  // nextBillingAt: Date;

  startDate?: Date;

  couponIds: string[];

  paymentMethod: PaymentType;

  payments?: PaymentDTO[];

  hardwareShippingAddress?: string;

  shippingDetails?: ShippingAddress;

  endOfTerm?: boolean;

  discountAmount?: number;

  invoiceSubject?: CRMInvoiceSubject;

  /** @desc for upgrade checkout flow */
  replace?: boolean;

  isPartial?: boolean;

  billingAddressInfo: BillingAddressInfo;
}

export class AdminOnlineZohoPaymentDTO extends AdminOnlinePaymentDTO {
  adminEmail: string;

  chargebeeInvoiceId: string;

  nextBillingAt: string;

  adminPanelInvoiceId: number;
}

export interface AllowedApp {
  featureId: string;

  featureName: string;

  isPlanEntitlement: boolean;
}

export interface AllowedModule {
  featureId: string;

  featureName: string;
}

export interface RewaaAccountSubscription {
  planId: string;

  subscriptionId: string;

  firstStartDate: string | Date;

  startDate: string | Date;

  endDate: string | Date;

  trialPlan: boolean;

  integratedChannelNo: number;

  maxStockLocationsNo: number;

  maxSku: number;

  tapCustomerId: string;

  tapCardId: string;

  locationAddonId: string;

  applicationAddonId: string;

  isDeactivated: boolean;

  createdAt: string | Date;

  updatedAt: string | Date;

  deletedAt: any;

  currentTermStart: number | null;
}

export interface SubscriptionDetails extends RewaaAccountSubscription {
  basePlanId?: string;

  unit?: string;

  period?: number;

  allowedModules?: AllowedModule[];

  planType?: string;

  status: string;

  trialEndDate: string | Date;

  allowedApps: AllowedApp[];

  allowedCustomFields: number;

  allowedLocations: number;

  planEntitledLocations: number;
}

export interface ItemEntitlements {
  descriptions: string[];
  entitlements: {
    [key: string]: string;
  };
}

export enum FeatureType {
  SKU = 'sku',
  LegacyApps = 'apps',
  Location = 'location',
  CustomFields = 'custom-fields',
}

export enum CRMInvoiceSubject {
  None = 'None',
  UpgradePlanWithRenew = 'UpgradePlanWithRenew',
  DowngradePlanWithRenew = 'DowngradePlanWithRenew',
}
export interface ItemPrice {
  id: string;

  name: string;

  item_family_id?: string;

  cf_CRM_Addon_Code?: string;

  cf_CRM_Charges?: string;

  product_id?: string;

  item_id?: string;

  description?: string;

  status?: string;

  external_name?: string;

  pricing_model: string;

  price?: number;

  price_in_decimal?: string;

  period?: number;

  currency_code: string;

  period_unit?: string;

  trial_period?: number;

  trial_period_unit?: string;

  trial_end_action?: string;

  shipping_period?: number;

  shipping_period_unit?: string;

  billing_cycles?: number;

  free_quantity: number;

  free_quantity_in_decimal?: string;

  channel?: string;

  resource_version?: number;

  updated_at?: number;

  created_at: number;

  archived_at?: number;

  invoice_notes?: string;

  is_taxable?: boolean;

  item_type?: string;

  archivable?: boolean;

  parent_item_id?: string;

  show_description_in_invoices?: boolean;

  show_description_in_quotes?: boolean;

  cf_CRM_Plan_Code?: string;

  cf_CRM_Location_Code?: string;
}

export interface ZohoCreditNotePayload {
  subscriptionId: string;
  adminEmail: string;
  userEmail: string;
  amountAvailable: number;
  refundableDiscount: number;
  reason: string;
  refundedDate: string | undefined;
  checkoutFlow: CheckOutFlows;
  invoiceIdFromChargebee: string;
  chargebeeCreditNoteNumber: string;
  creditNoteType: string;
  note?: string | undefined;
  refundableAmount: number;
  totalAmountPaid: number;
  productInformation: SubscriptionItem[];
}

export interface CreateCreditNotePayload {
  zohoCreditNotePayload: ZohoCreditNotePayload;
  chargebeePrices: ItemPrice[];
  subscriptionStartDate: string;
  subscriptionEndDate: string;
}
