export enum HttpMethodType {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export const TenantIdHeader = 'tenant-id-header';

export enum AppName {
  WooCommerce = 'woocommerce',
  PointOfSale = 'point of sale',
  InternalPOS = 'new POS',
  Magento = 'magento',
  ZID = 'zid',
  SALLA = 'salla',
  Bonat = 'Bonat',
  Qoyod = 'qoyod',
  Promotions = 'promotion management',
  Expenses = 'expenses',
  Accounting = 'accounting',
  Zatca = 'zatca',
}

export enum InternalService {
  Accounting = 'accounting',
  Nucleus = 'nucleus',
  Marketing = 'marketing',
  Expense = 'expense',
  Mims = 'mims',
  Enigma = 'enigma',
  Omni = 'omni',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum AccountingActivityType {
  AccountingLedger = 'acc-ledger',
}

export enum TaxType {
  TaxExclusive = 'TAX_EXCLUSIVE',
  TaxInclusive = 'TAX_INCLUSIVE',
  NoTax = 'NO_TAX',
}

export enum DefaultTaxPercentage {
  VAT = 15,
}

export enum SecondaryIDEnum {
  CommercialRegistration = 'Commercial Registration',
  FreelanceLicense = 'Freelance License',
  MunicipalLicense = 'Municipal License',
  InvestmentLicense = 'Investment License',
  MOMRALicense = 'MOMRA License',
  MOMRALicenses = 'MOMRA licenses',
  MLSDLicense = 'MLSD licenses',
  MLSDLicenses = 'MLSD License',
  SAGIALicense = 'SAGIA License',
  SAGIALicenses = 'SAGIA licenses',
  OtherID = 'Other ID',
}

export const CacheServiceToken = 'CacheServiceToken';
