import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  firstValueFrom,
  last,
  lastValueFrom,
  map,
  tap,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountHeadState,
  DefaultAccountHeadsReferenceURI,
  JournalEntryState,
  TransactionMappingEffectType,
  TransactionMappingType,
  TransactionType,
} from '@rewaa-team/rewaa-accounting';
import { BigNumber } from '@rewaa-team/rewaa-calculation';
import { environment } from '../../environments/environment';
import {
  AccountHeadTransaction,
  ArchiveAccountHeadRequest,
  CreateSubHeadRequest,
  GetAccountHeadsResponse,
  JournalEntryResponse,
  JournalEntryResponseRaw,
  LeafLedgerEntriesResponse,
  LeafLedgerEntriesResponseRaw,
  PaginatedResponse,
  RestoreAccountHeadRequest,
  UpdateAccountHeadRequest,
} from '../accounting/accounting.types';
import {
  ConfigurationGroup,
  ConfigurationName,
  ConfigurationService,
  IConfiguration,
} from '../configuration/configuration.service';
import {
  AccountHeadInputPayload,
  GetTransactionMappingJobs,
} from './advanced-accounting.types';
import { ExportFileTypes } from '../reports/utils';
import { AcHeadTransPipe } from '../shared/pipes/ah-translate.pipe';
import { AcHeadCodeTransPipe } from '../shared/pipes/ah-code-translate.pipe';

export type OpeningBalanceResponse = {
  requestTransactionId: string;
  type: string;
  referenceId: number;
  amount: number;
  date: Date;
};

export type TransactionMappingDetails = {
  transactionMappingEffectType: TransactionMappingEffectType;
  effectiveDate?: Date;
  accountHeadReMappings: {
    [x: string]: string;
  };
};

export type GetTransactionMappingDetails = TransactionMappingDetails & {
  lastUserUpdate: {
    id: string;
    roles: {
      id: number;
      type: string;
      level: string;
    };
    name: string;
    updatedAt: string;
    mappingEffectType: TransactionMappingEffectType;
    effectiveDate: string;
  };
};

export type PutTransactionMappings = {
  [TransactionMappingType.ImportAddDeleteProduct]?: TransactionMappingDetails;
  [TransactionMappingType.StockCount]?: TransactionMappingDetails;
  [TransactionMappingType.RemoveStock]?: TransactionMappingDetails;
};

export type GetTransactionMappings = {
  [TransactionMappingType.ImportAddDeleteProduct]?: GetTransactionMappingDetails;
  [TransactionMappingType.StockCount]?: GetTransactionMappingDetails;
  [TransactionMappingType.RemoveStock]?: GetTransactionMappingDetails;
};

export type GetPaymentMethodsMappings = {
  [id: number]: string;
}[];

@Injectable()
export class AdvancedAccountingService {
  private API_URL = environment.accountingApiPath;

  private allAccountHeadsSubject: ReplaySubject<GetAccountHeadsResponse>;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private readonly accountHeadNameTrnPipe: AcHeadTransPipe,
    private readonly accountHeadCodeTrnPipe: AcHeadCodeTransPipe,
  ) {}

  async getAccountHeadsWithAllHeads(
    leafOnly = false,
    notIncludeChildHeadsOf = [],
  ): Promise<GetAccountHeadsResponse> {
    return firstValueFrom(
      this.getAccountHeadsApiCall({
        notIncludeChildHeadsOf,
        leafOnly,
      }),
    );
  }

  getAllAccountHeads(
    input?: AccountHeadInputPayload,
    resetData?: boolean,
  ): Observable<GetAccountHeadsResponse> {
    if (!this.allAccountHeadsSubject || resetData) {
      this.allAccountHeadsSubject = new ReplaySubject(1);
      this.refreshAllAccountHeads(input);
    }
    return this.allAccountHeadsSubject.asObservable();
  }

  private refreshAllAccountHeads(input?: AccountHeadInputPayload) {
    this.getAccountHeadsApiCall(input)
      .pipe(last())
      .subscribe((accountHeadsResponse) => {
        const convertedAccountHeads = accountHeadsResponse.accountHeads.map(
          (aH) => ({
            ...aH,
            balance: new BigNumber(aH.balance),
          }),
        );

        const updatedResponse = {
          ...accountHeadsResponse,
          accountHeads: convertedAccountHeads,
        };

        this.allAccountHeadsSubject.next(updatedResponse);
      });
  }

  getAccountHeadsApiCall(
    input?: AccountHeadInputPayload,
  ): Observable<GetAccountHeadsResponse> {
    const params = {
      ...(input?.ulids?.length ? { 'ulids[]': input.ulids } : {}),
      ...(input?.parentHeadRefs?.length
        ? { parentHeadRefs: input.parentHeadRefs }
        : {}),
      ...(input?.referenceUris?.length
        ? { referenceUris: input.referenceUris }
        : {}),
      ...(input?.notIncludeReferenceUris?.length
        ? { 'notIncludeReferenceUris[]': input.notIncludeReferenceUris }
        : {}),
      ...(input?.notIncludeChildHeadsOf?.length >= 0
        ? { notIncludeChildHeadsOf: input.notIncludeChildHeadsOf }
        : {}),
      ...(input?.leafOnly ? { leafOnly: input.leafOnly } : {}),
      ...(input?.state ? { state: input.state } : {}),
      ...(input?.code ? { code: input.code } : ''),
      ...(input?.limit ? { limit: input.limit } : ''),
      ...(input?.offset ? { offset: input.offset } : ''),
      ...(input?.search ? { search: input.search } : ''),
    };
    return this.http.get<GetAccountHeadsResponse>(
      `${this.API_URL}/coa/account-heads`,
      { params },
    );
  }

  async getAccountHeads(input?: {
    ulids?: string[];
    referenceUris?: string[];
    parentHeadRefs?: string[];
    notIncludeReferenceUris?: string[];
    limit?: number;
    offset?: number;
    search?: string;
  }): Promise<GetAccountHeadsResponse> {
    return firstValueFrom(
      this.getAccountHeadsApiCall({
        ulids: input?.ulids,
        referenceUris: input?.referenceUris,
        parentHeadRefs: input?.parentHeadRefs,
        notIncludeReferenceUris: input?.notIncludeReferenceUris,
        limit: input?.limit,
        offset: input?.offset,
        search: input?.search,
      }),
    );
  }

  async searchAccountHeads(
    input?: AccountHeadInputPayload,
  ): Promise<GetAccountHeadsResponse> {
    const params = {
      ...(input?.ulids?.length ? { 'ulids[]': input.ulids } : {}),
      ...(input?.parentHeadRefs?.length
        ? { parentHeadRefs: input.parentHeadRefs }
        : {}),
      ...(input?.referenceUris?.length
        ? { referenceUris: input.referenceUris }
        : {}),
      ...(input?.notIncludeReferenceUris?.length
        ? { 'notIncludeReferenceUris[]': input.notIncludeReferenceUris }
        : {}),
      ...(input?.notIncludeChildHeadsOf?.length >= 0
        ? { notIncludeChildHeadsOf: input.notIncludeChildHeadsOf }
        : {}),
      ...(input?.leafOnly ? { leafOnly: input.leafOnly } : {}),
      ...(input?.state ? { state: input.state } : {}),
      ...(input?.code ? { code: input.code } : ''),
      ...(input?.limit ? { limit: input.limit } : ''),
      ...(input?.offset ? { offset: input.offset } : ''),
      ...(input?.search ? { search: input.search } : ''),
      ...{ lang: this.translateService.currentLang },
    };
    return firstValueFrom(
      this.http.get<GetAccountHeadsResponse>(
        `${this.API_URL}/coa/account-heads/search`,
        { params },
      ),
    );
  }

  async getOpeningBalance(
    entityIds: number[],
    entityType: string,
  ): Promise<OpeningBalanceResponse[]> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('entityType', entityType);

    entityIds.forEach((entityId) => {
      httpParams = httpParams.append('entityIds[]', entityId);
    });

    return firstValueFrom(
      this.http.get<OpeningBalanceResponse[]>(
        `${this.API_URL}/transaction/opening-balance`,
        {
          params: httpParams,
        },
      ),
    );
  }

  async requestAccountHeadTransactionsExport({
    ulid,
    labelsIds,
    startDate,
    endDate,
    fileType,
    lang,
    uuid,
  }: {
    ulid: string;
    labelsIds?: number[];
    startDate?: Date;
    endDate?: Date;
    fileType: ExportFileTypes;
    lang: string;
    uuid: string;
  }): Promise<PaginatedResponse<AccountHeadTransaction>> {
    return firstValueFrom(
      this.http.post<PaginatedResponse<AccountHeadTransaction>>(
        `${this.API_URL}/transaction/account-head/${ulid}/export`,
        {
          startDate,
          endDate,
          labelsIds: labelsIds?.length ? labelsIds : undefined,
          limit: 9999999999,
          fileType,
          lang,
          uuid,
        },
      ),
    );
  }

  async getTransactions({
    offset,
    limit,
    currencyCode,
    entryId,
    startDate,
    endDate,
    sortBy,
    sortOrder,
    requestTransactionTypes,
    referenceIds,
    status,
  }: {
    offset?: number;
    limit?: number;
    currencyCode: string;
    entryId?: string;
    startDate?: string;
    endDate?: string;
    sortBy?: string;
    sortOrder?: string;
    requestTransactionTypes?: string[];
    referenceIds?: number[];
    status?: JournalEntryState[];
  }): Promise<PaginatedResponse<JournalEntryResponse>> {
    let params = new HttpParams()
      .set('offset', offset || '0')
      .set('limit', limit || '10')
      .set('currencyCode', currencyCode)
      .set('entryId', entryId || '')
      .set('startDate', startDate || '')
      .set('endDate', endDate || '')
      .set('sortBy', sortBy || '')
      .set('sortOrder', sortOrder || '');

    if (referenceIds?.length) {
      referenceIds.forEach((referenceId) => {
        params = params.append('referenceIds[]', referenceId);
      });
    }
    if (requestTransactionTypes?.length) {
      requestTransactionTypes.forEach((requestTransactionType) => {
        params = params.append(
          'requestTransactionTypes[]',
          requestTransactionType,
        );
      });
    }
    if (status?.length) {
      status.forEach((state) => {
        params = params.append('status[]', state);
      });
    }

    const options = { params };

    // Filter out any keys with empty values
    options.params.keys().forEach((key) => {
      if (!options.params.get(key)) {
        options.params = options.params.delete(key);
      }
    });

    return firstValueFrom(
      this.http
        .get<
          PaginatedResponse<JournalEntryResponseRaw>
        >(`${this.API_URL}/transaction/journal-entries`, options)
        .pipe(
          map((response) => ({
            ...response,
            result: response.result.map((entry) => ({
              ...entry,
              totalAmount: new BigNumber(entry.totalAmount),
              metadata: {
                ...entry.metadata,
                fiscalPeriodAmount: entry.metadata?.fiscalPeriodAmount
                  ? new BigNumber(entry.metadata.fiscalPeriodAmount)
                  : new BigNumber(0),
              },
            })),
          })),
        ),
    );
  }

  async searchJournalEntries({
    offset,
    limit,
    currencyCode,
    entryId,
    startDate,
    endDate,
    sortBy,
    sortOrder,
    requestTransactionTypes,
    status,
  }: {
    offset?: number;
    limit?: number;
    currencyCode: string;
    entryId?: string;
    startDate?: string;
    endDate?: string;
    sortBy?: string;
    sortOrder?: string;
    requestTransactionTypes?: string[];
    status?: JournalEntryState[];
  }): Promise<PaginatedResponse<JournalEntryResponse>> {
    let params = new HttpParams()
      .set('offset', offset || '0')
      .set('limit', limit || '10')
      .set('currencyCode', currencyCode)
      .set('entryId', entryId || '')
      .set('startDate', startDate || '')
      .set('endDate', endDate || '')
      .set('sortBy', sortBy || '')
      .set('sortOrder', sortOrder || '');

    if (requestTransactionTypes?.length) {
      requestTransactionTypes.forEach((requestTransactionType) => {
        params = params.append(
          'requestTransactionTypes[]',
          requestTransactionType,
        );
      });
    }

    if (status?.length) {
      status.forEach((state) => {
        params = params.append('status[]', state);
      });
    }

    const options = { params };

    // Filter out any keys with empty values
    options.params.keys().forEach((key) => {
      if (!options.params.get(key)) {
        options.params = options.params.delete(key);
      }
    });

    return firstValueFrom(
      this.http
        .get<
          PaginatedResponse<JournalEntryResponseRaw>
        >(`${this.API_URL}/transaction/journal-entries/search`, options)
        .pipe(
          map((response) => ({
            ...response,
            result: response.result.map((entry) => ({
              ...entry,
              totalAmount: new BigNumber(entry.totalAmount),
              metadata: {
                ...entry.metadata,
                fiscalPeriodAmount: entry.metadata?.fiscalPeriodAmount
                  ? new BigNumber(entry.metadata.fiscalPeriodAmount)
                  : new BigNumber(0),
              },
            })),
          })),
        ),
    );
  }

  async reverseJournalEntry(requestTransactionId: string): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/transaction/reverse`, {
        requestTransactionId,
      }),
    );
  }

  async deleteJournalEntry(requestTransactionId: string): Promise<unknown> {
    return firstValueFrom(
      this.http.delete(`${this.API_URL}/transaction/${requestTransactionId}`),
    );
  }

  getSetting(name: ConfigurationName): Observable<IConfiguration> {
    return this.configurationService.get(
      name,
      ConfigurationGroup.ADVANCE_ACCOUNTING,
    );
  }

  upsertSetting(config: IConfiguration): Observable<IConfiguration> {
    return this.configurationService.set(config);
  }

  completeAppSetup(params: unknown): Promise<any> {
    return firstValueFrom(this.http.post(`/api/update-subscriptions`, params));
  }

  accountHeadTranslationHelper(title = '', separator = ' '): string {
    return this.accountHeadNameTrnPipe.transform(title, separator);
  }

  accountHeadCodeTranslationHelper(code: string): string {
    return this.accountHeadCodeTrnPipe.transform(code);
  }

  conditionalAccountHeadTranslationHelper(
    title: string,
    accountHeadRefURI: string,
  ): string {
    let separator = ' ';
    if (
      this.startsWithAny(accountHeadRefURI, [
        DefaultAccountHeadsReferenceURI.AccountReceivable,
        DefaultAccountHeadsReferenceURI.AccountPayable,
      ])
    ) {
      separator = ' - ';
    }
    return this.accountHeadTranslationHelper(title, separator);
  }

  startsWithAny(value: string, prefixes: string[]): boolean {
    return prefixes.some((prefix) => value.startsWith(prefix));
  }

  async createAccountHeadV2(
    body:
      | (Pick<
          CreateSubHeadRequest,
          | 'code'
          | 'description'
          | 'parentHeadUlid'
          | 'title'
          | 'bankAccountNumber'
        > & {
          openingBalance: {
            amount: BigNumber;
            date: string;
          };
        })
      | { name: string },
  ): Promise<void> {
    await lastValueFrom(
      this.http.post(`${this.API_URL}/coa/account-head/v2`, body).pipe(
        tap(() => {
          this.refreshAllAccountHeads();
        }),
      ),
    );
  }

  async updateAccountHeadV2(body: UpdateAccountHeadRequest): Promise<void> {
    await lastValueFrom(
      this.http
        .patch(`${this.API_URL}/coa/account-head/v2/${body.accountHeadUlid}`, {
          title: body.title,
          description: body.description,
          code: body.code,
          bankAccountNumber: body.bankAccountNumber,
        })
        .pipe(
          tap(() => {
            this.refreshAllAccountHeads();
          }),
        ),
    );
  }

  async archiveAccountHead(data: ArchiveAccountHeadRequest): Promise<any> {
    return lastValueFrom(
      this.http
        .patch(
          `${this.API_URL}/coa/account-head/${data.accountHeadUlid}/state`,
          {
            state: AccountHeadState.Archived,
          },
        )
        .pipe(
          tap(() => {
            // TODO: Change this to node addition and deletion in preformed tree
            this.refreshAllAccountHeads();
          }),
        ),
    );
  }

  async restoreAccountHead(data: RestoreAccountHeadRequest): Promise<any> {
    return lastValueFrom(
      this.http
        .patch(
          `${this.API_URL}/coa/account-head/${data.accountHeadUlid}/state`,
          {
            state: AccountHeadState.Active,
          },
        )
        .pipe(
          tap(() => {
            // TODO: Change this to node addition and deletion in preformed tree
            this.refreshAllAccountHeads();
          }),
        ),
    );
  }

  formatReferenceIdForDeleteProduct = (referenceId: string): string => {
    if (referenceId.length > 13) {
      return `${referenceId.slice(0, 13)}...`;
    }
    return referenceId;
  };

  getLeafLedgerBalances(params: {
    startDate: Date;
    endDate: Date;
  }): Promise<LeafLedgerEntriesResponse[]> {
    return firstValueFrom(
      this.http
        .get<LeafLedgerEntriesResponseRaw[]>(
          `${this.API_URL}/coa/account-head/balance-changes`,
          {
            params: {
              startDate: params.startDate as unknown as string,
              endDate: params.endDate as unknown as string,
            },
          },
        )
        .pipe(
          map((entries: LeafLedgerEntriesResponseRaw[]) =>
            entries.map((entry) => ({
              ...entry,
              balance: new BigNumber(entry.balance),
            })),
          ),
        ),
    );
  }

  public async putTransactionMappings(
    mappings: PutTransactionMappings,
  ): Promise<void> {
    await firstValueFrom(
      this.http.put(`${this.API_URL}/transaction-mappings`, mappings),
    );
  }

  public async getPaymentMethodsMappings(): Promise<GetPaymentMethodsMappings> {
    return firstValueFrom(
      this.http.get<GetPaymentMethodsMappings>(
        `${this.API_URL}/transaction-mappings/payment-methods`,
      ),
    );
  }

  public async getTransactionMappings(): Promise<GetTransactionMappings> {
    return firstValueFrom(
      this.http.get<GetTransactionMappings>(
        `${this.API_URL}/transaction-mappings`,
      ),
    );
  }

  public async getTransactionMappingJobs(): Promise<
    GetTransactionMappingJobs[]
  > {
    return firstValueFrom(
      this.http.get<GetTransactionMappingJobs[]>(
        `${this.API_URL}/transaction-mappings/jobs`,
      ),
    );
  }

  public async getTransactionTypes(): Promise<TransactionType[]> {
    return firstValueFrom(
      this.http.get<TransactionType[]>(
        `${this.API_URL}/transaction/transaction-types`,
      ),
    );
  }
}
